import request from "../utils/https";

/**
 * 个人作业统计报告智能点评 （新版）
 * @returns {AxiosPromise}
 */
export function newPersonalReviews(data) {
    return request({
        url: '/apiv2/teacher/homework/getHomeworkAiAnalysis',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}
/**
 * 获取字典信息
 * @returns {AxiosPromise}
 */
export function getDictInfo(data) {
    return request({
        url: '/apiv2/teacher/common/getDictInfo',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}
